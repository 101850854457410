<template>
  <section id="performance-overview">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedEmployees" label="title" multiple :options="allEmployees"
            @input="changeSelectedEmployees" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showOrdersOffersOverview" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Conversion rate
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-conversion-rate" />
          </h4>
          <b-popover target="popover-conversion-rate" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="conversionRateChart" :options="conversionRateChart" style="width: 100%; height: 500px;" />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Absolute values ('retoure' excluded)
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-absolute-values" />
          </h4>
          <b-popover target="popover-absolute-values" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button-group>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="showPrevYearModal">
                Prev. Year Values
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="showAvgValuesModal">
                Avg. Values By Month
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                @click="exportOrdersOffersByEmployeeTable">
                Export
              </b-button>
            </b-button-group>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="ordersOffersByEmployeeTable.currentPage" :items="ordersOffersByEmployeeTable.items"
          :fields="ordersOffersByEmployeeTable.fields" :sort-by.sync="ordersOffersByEmployeeTable.sortBy"
          :sort-desc.sync="ordersOffersByEmployeeTable.sortDesc"
          :sort-direction="ordersOffersByEmployeeTable.sortDirection" :filter="ordersOffersByEmployeeTable.filter"
          :filter-included-fields="ordersOffersByEmployeeTable.filterOn"
          @row-clicked="ordersOffersByEmployeeTableRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="ordersOffersByEmployeeTable.currentPage"
              :total-rows="ordersOffersByEmployeeTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-row>
        <b-col xl="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ webshopTotalTurnover | formatCurrencyNumber }}
                </h2>
                <span>Webshop Total Turnover</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ employeesTotalTurnover | formatCurrencyNumber }}
                </h2>
                <span>Employees Total Turnover</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ (webshopTotalTurnover + employeesTotalTurnover) | formatCurrencyNumber }}
                </h2>
                <span>Webshop + Employees Total Turnover</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ webshopTotalMargin | formatCurrencyNumber }}
                </h2>
                <span>Webshop Total Margin</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalMargin | formatCurrencyNumber }}
                </h2>
                <span>Employees Total Margin</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ (webshopTotalMargin + totalMargin) | formatCurrencyNumber }}
                </h2>
                <span>Webshop + Employees Total Margin</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalOffersAmount | formatCurrencyNumber }}
                </h2>
                <span>Employees Total Offers Amount</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalOpenOrders | formatCurrencyNumber }}
                </h2>
                <span>Employees Total Open Orders</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalPersonalCosts | formatCurrencyNumber }}
                </h2>
                <span>Employees Total Personal Costs</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="3">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalRealMargin | formatCurrencyNumber }}
                </h2>
                <span>Employees Total Real Margin</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-modal ref="employee-details-modal" size="xl" :title="employeeName" cancel-title="Close"
        cancel-variant="outline-secondary" :no-close-on-backdrop="true" @shown="onEmployeeDetailsModalShown"
        @hidden="resetEmployeeDetailsModal">
        <b-row>
          <b-col xl="6">
            <b-overlay :show="showEmployeeOffers" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Offers
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offers" />
                  </h4>
                  <b-popover target="popover-offers" triggers="hover" placement="bottom">
                    <span>No data</span>
                  </b-popover>
                </b-card-header>
                <b-table striped hover responsive class="position-relative"
                  :current-page="employeeOffersTable.currentPage" :items="employeeOffersTable.items"
                  :fields="employeeOffersTable.fields" :sort-by.sync="employeeOffersTable.sortBy"
                  :sort-desc.sync="employeeOffersTable.sortDesc" :sort-direction="employeeOffersTable.sortDirection"
                  :filter="employeeOffersTable.filter" :filter-included-fields="employeeOffersTable.filterOn"
                  @sort-changed="employeeOffersTableSortChanged" />
                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                  <!-- pagination -->
                  <div>
                    <b-pagination v-model="employeeOffersTable.currentPage" :total-rows="employeeOffersTable.totalRows"
                      first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                      @change="handleEmployeeOffersTablePageChange">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
          <b-col xl="6">
            <b-overlay :show="showEmployeeOrders" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Orders
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders" />
                  </h4>
                  <b-popover target="popover-orders" triggers="hover" placement="bottom">
                    <span>No data</span>
                  </b-popover>
                </b-card-header>
                <b-table striped hover responsive class="position-relative"
                  :current-page="employeeOrdersTable.currentPage" :items="employeeOrdersTable.items"
                  :fields="employeeOrdersTable.fields" :sort-by.sync="employeeOrdersTable.sortBy"
                  :sort-desc.sync="employeeOrdersTable.sortDesc" :sort-direction="employeeOrdersTable.sortDirection"
                  :filter="employeeOrdersTable.filter" :filter-included-fields="employeeOrdersTable.filterOn"
                  @sort-changed="employeeOrdersTableSortChanged" />
                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                  <!-- pagination -->
                  <div>
                    <b-pagination v-model="employeeOrdersTable.currentPage" :total-rows="employeeOrdersTable.totalRows"
                      first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                      @change="handleEmployeeOrdersTablePageChange">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Top customers
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-customers" />
            </h4>
            <b-popover target="popover-top-customers" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <ECharts ref="topCustomersChart" :options="topCustomersChart" style="width: 100%; height: 500px;" />
        </b-card>
      </b-modal>
      <b-modal ref="absolute-values-prev-year-modal" size="xl" title="Absolute Values Prev. Year ('Retoure' excluded)"
        cancel-title="Close" cancel-variant="outline-secondary" :no-close-on-backdrop="true">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Orders offers by employee
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-orders-offers-employee" />
            </h4>
            <b-popover target="popover-orders-offers-employee" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table striped hover responsive class="position-relative"
            :current-page="ordersOffersByEmployeePrevYearTable.currentPage"
            :items="ordersOffersByEmployeePrevYearTable.items" :fields="ordersOffersByEmployeePrevYearTable.fields"
            :sort-by.sync="ordersOffersByEmployeePrevYearTable.sortBy"
            :sort-desc.sync="ordersOffersByEmployeePrevYearTable.sortDesc"
            :sort-direction="ordersOffersByEmployeePrevYearTable.sortDirection"
            :filter="ordersOffersByEmployeePrevYearTable.filter"
            :filter-included-fields="ordersOffersByEmployeePrevYearTable.filterOn" />
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="ordersOffersByEmployeePrevYearTable.currentPage"
                :total-rows="ordersOffersByEmployeePrevYearTable.totalRows" first-number last-number
                prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-modal>
      <b-modal ref="avg-values-modal" size="xl" title="Avg. Values By Month" cancel-title="Close"
        cancel-variant="outline-secondary" :no-close-on-backdrop="true">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Avg. values
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-avg-values" />
            </h4>
            <b-popover target="popover-avg-values" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table striped hover responsive class="position-relative" :current-page="avgValuesTable.currentPage"
            :items="avgValuesTable.items" :fields="avgValuesTable.fields" :sort-by.sync="avgValuesTable.sortBy"
            :sort-desc.sync="avgValuesTable.sortDesc" :sort-direction="avgValuesTable.sortDirection"
            :filter="avgValuesTable.filter" :filter-included-fields="avgValuesTable.filterOn"
            :tbody-tr-class="rowClass" />
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="avgValuesTable.currentPage" :total-rows="avgValuesTable.totalRows" first-number
                last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-modal>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Webshop turnover
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-webshop-turnover" />
              </h4>
              <b-popover target="popover-webshop-turnover" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="webshopTurnoverChart" :options="webshopTurnoverChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Webshop margin
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-webshop-margin" />
              </h4>
              <b-popover target="popover-webshop-margin" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="webshopMarginChart" :options="webshopMarginChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers total amount
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-offers-total-amount" />
              </h4>
              <b-popover target="popover-offers-total-amount" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="offersTotalAmountChart" :options="offersTotalAmountChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-turnover" />
              </h4>
              <b-popover target="popover-turnover" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersTotalAmountChart" :options="ordersTotalAmountChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers number
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offers-number" />
              </h4>
              <b-popover target="popover-offers-number" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="offersNumberChart" :options="offersNumberChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders number
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-number" />
              </h4>
              <b-popover target="popover-orders-number" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersNumberChart" :options="ordersNumberChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers total items qty
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-offers-total-items-qty" />
              </h4>
              <b-popover target="popover-offers-total-items-qty" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="offersTotalQtyChart" :options="offersTotalQtyChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders total items qty
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-total-items-qty" />
              </h4>
              <b-popover target="popover-orders-total-items-qty" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersTotalQtyChart" :options="ordersTotalQtyChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="showWorkingHoursByTeam" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Working hours by team
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-working-hours-team" />
          </h4>
          <b-popover target="popover-working-hours-team" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="workingHoursByTeamChart" :options="workingHoursByTeamChart" style="width: 100%; height: 500px;" />
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BRow,
  BCol,
  BOverlay,
  BTable,
  BModal,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BButtonGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BModal,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BButtonGroup,
    vSelect,
    flatPickr,
    ECharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showOrdersOffersOverview: true,
      showWorkingHoursByTeam: true,
      showEmployeeOffers: true,
      showEmployeeOrders: true,
      showWebshopTurnover: false,
      showTopCustomers: true,
      oldDateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      selectedEmployees: [],
      allEmployees: [],
      employeeName: '',
      webshopTotalTurnover: 0.0,
      employeesTotalTurnover: 0.0,
      totalOffersAmount: 0.0,
      totalOpenOrders: 0.0,
      totalPersonalCosts: 0.0,
      totalMargin: 0.0,
      totalRealMargin: 0.0,
      webshopTotalMargin: 0.0,
      queryParams: {},
      queryParamsPrevYear: {},
      queryParamsEmployeeOffers: {},
      queryParamsEmployeeOrders: {},
      queryParamsEmployeeTopCustomers: {},
      conversionRateChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      workingHoursByTeamChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgValuesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'avgTurnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'avgOrdersNumber',
            label: 'Orders Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'avgOffersNumber',
            label: 'Offers Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'avgConversionRate',
            label: 'Conversion Rate',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'avgTurnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avgOffersTotalAmount',
            label: 'Offers Total Amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avgOrdersTotalQty',
            label: 'Orders Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'avgOffersTotalQty',
            label: 'Offers Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      ordersOffersByEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'name', sortable: true },
          {
            key: 'orders_count',
            label: 'Orders Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_count',
            label: 'Offers Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'conversion_rate',
            label: 'Conversion Rate',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'orders_total_qty',
            label: 'Orders Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_total_qty',
            label: 'Offers Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_value',
            label: 'Offers Total Amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'open_orders_value',
            label: 'open orders',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'personal_costs',
            label: 'personal costs',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'real_margin',
            label: 'real margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'real_margin_p',
            label: 'real margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      ordersOffersByEmployeePrevYearTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'name', sortable: true },
          {
            key: 'orders_count',
            label: 'Orders Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_count',
            label: 'Offers Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'conversion_rate',
            label: 'Conversion Rate',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'orders_total_qty',
            label: 'Orders Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_total_qty',
            label: 'Offers Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_value',
            label: 'Offers Total Amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'personal_costs',
            label: 'personal costs',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'real_margin',
            label: 'real margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'real_margin_p',
            label: 'real margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      webshopTurnoverChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      webshopMarginChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersTotalAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersNumberChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersTotalQtyChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersTotalAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersNumberChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersTotalQtyChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      employeeOffersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'offer_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          {
            key: 'offer_amount',
            label: 'offer amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'customer_name', label: 'customer name', sortable: true },
          {
            key: 'offer_date',
            label: 'offer date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      employeeOrdersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'order_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'invoice_no', label: 'invoice no', sortable: true },
          { key: 'order_no', label: 'order no', sortable: true },
          {
            key: 'order_amount',
            label: 'order amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin',
            label: 'margin',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'margin_p',
            label: 'margin %',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          { key: 'customer_name', label: 'customer name', sortable: true },
          {
            key: 'order_date',
            label: 'order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      topCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    const fromDateSelectedYearDate = new Date(dateRange[0]);
    const toDateSelectedYearDate = new Date(dateRange[1]);
    const fromDatePrevYearDate = new Date(fromDateSelectedYearDate.getFullYear() - 1, fromDateSelectedYearDate.getMonth(), fromDateSelectedYearDate.getDate());
    const toDatePrevYearDate = new Date(toDateSelectedYearDate.getFullYear() - 1, toDateSelectedYearDate.getMonth(), toDateSelectedYearDate.getDate());

    // Format dates
    const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];
    this.queryParamsPrevYear.from_date = formatDate(fromDatePrevYearDate);
    this.queryParamsPrevYear.to_date = formatDate(toDatePrevYearDate);

    try {
      await this.getEmployeeNames();
      this.queryParams.selected_employees = this.selectedEmployees;
      await this.getOrdersOffersByEmployee();
      await this.getOrdersOffersByEmployeePrevYear();
      await this.getOrdersOffersOverview();
      await this.getWebshopTurnover();
      await this.getWorkingHoursByTeam();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.name === 'All') return 'table-success';
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    updateOrdersOffersCharts(data) {
      const dataOrdersOffers = data.data.results;
      let employeeNames = dataOrdersOffers.map(item => item.employee);
      let dates = dataOrdersOffers.map(item => item.month_year);
      employeeNames = [...new Set(employeeNames)];
      dates = [...new Set(dates)];

      const offersTotalAmountChartDatasets = [];
      const offersNumberChartDatasets = [];
      const offersTotalQtyChartDatasets = [];
      const ordersTotalAmountChartDatasets = [];
      const ordersNumberChartDatasets = [];
      const ordersTotalQtyChartDatasets = [];
      const conversionRateChartDatasets = [];
      const avgValues = [];
      let findValues;

      for (var i = 0; i < employeeNames.length; i++) {
        const offersTotalAmount = [];
        const offersNumber = [];
        const offersTotalQty = [];
        const ordersTotalAmount = [];
        const ordersNumber = [];
        const ordersTotalQty = [];
        const conversionRate = [];

        let offersTotalAmountLength = 0;
        let offersNumberLength = 0;
        let offersTotalQtyLength = 0;
        let ordersTotalAmountLength = 0;
        let ordersNumberLength = 0;
        let ordersTotalQtyLength = 0;
        let conversionRateLength = 0;

        for (var j = 0; j < dates.length; j++) {
          findValues = false;
          for (var k = 0; k < dataOrdersOffers.length; k++) {
            if (employeeNames[i] === dataOrdersOffers[k].employee && dates[j] === dataOrdersOffers[k].month_year) {
              offersTotalAmount.push(dataOrdersOffers[k].offers_value);
              offersNumber.push(dataOrdersOffers[k].offers_count);
              offersTotalQty.push(dataOrdersOffers[k].offers_total_qty);
              ordersTotalAmount.push(dataOrdersOffers[k].turnover);
              ordersNumber.push(dataOrdersOffers[k].orders_count);
              ordersTotalQty.push(dataOrdersOffers[k].orders_total_qty);
              conversionRate.push(dataOrdersOffers[k].conversion_rate);

              offersTotalAmountLength += 1;
              offersNumberLength += 1;
              offersTotalQtyLength += 1;
              ordersTotalAmountLength += 1;
              ordersNumberLength += 1;
              ordersTotalQtyLength += 1;
              conversionRateLength += 1;

              findValues = true;
              dataOrdersOffers.splice(k, 1);

              break;
            }
          }
          if (!findValues) {
            offersTotalAmount.push(0.0);
            offersNumber.push(0.0);
            offersTotalQty.push(0.0);
            ordersTotalAmount.push(0.0);
            ordersNumber.push(0.0);
            ordersTotalQty.push(0.0);
            conversionRate.push(0.0);
          }
        }

        offersTotalAmountChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: offersTotalAmount,
        });
        offersNumberChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: offersNumber,
        });
        offersTotalQtyChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: offersTotalQty,
        });
        ordersTotalAmountChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: ordersTotalAmount,
        });
        ordersNumberChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: ordersNumber,
        });
        ordersTotalQtyChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: ordersTotalQty,
        });
        conversionRateChartDatasets.push({
          name: employeeNames[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: conversionRate,
        });

        avgValues.push({
          name: employeeNames[i],
          avgOrdersNumber: (ordersNumber.reduce((a, b) => (a + b)) / ordersNumberLength),
          avgOffersNumber: (offersNumber.reduce((a, b) => (a + b)) / offersNumberLength),
          avgConversionRate: (conversionRate.reduce((a, b) => (a + b)) / conversionRateLength),
          avgTurnover: (ordersTotalAmount.reduce((a, b) => (a + b)) / ordersTotalAmountLength),
          avgOffersTotalAmount: (offersTotalAmount.reduce((a, b) => (a + b)) / offersTotalAmountLength),
          avgOrdersTotalQty: (ordersTotalQty.reduce((a, b) => (a + b)) / ordersTotalQtyLength),
          avgOffersTotalQty: (offersTotalQty.reduce((a, b) => (a + b)) / offersTotalQtyLength),
        });
      }

      avgValues.push({
        name: 'All',
        avgOrdersNumber: (avgValues.map(item => parseFloat(item.avgOrdersNumber)).reduce((a, b) => (a + b)) / avgValues.length),
        avgOffersNumber: (avgValues.map(item => parseFloat(item.avgOffersNumber)).reduce((a, b) => (a + b)) / avgValues.length),
        avgConversionRate: (avgValues.map(item => parseFloat(item.avgConversionRate)).reduce((a, b) => (a + b)) / avgValues.length),
        avgTurnover: (avgValues.map(item => parseFloat(item.avgTurnover)).reduce((a, b) => (a + b)) / avgValues.length),
        avgOffersTotalAmount: (avgValues.map(item => parseFloat(item.avgOffersTotalAmount)).reduce((a, b) => (a + b)) / avgValues.length),
        avgOrdersTotalQty: (avgValues.map(item => parseFloat(item.avgOrdersTotalQty)).reduce((a, b) => (a + b)) / avgValues.length),
        avgOffersTotalQty: (avgValues.map(item => parseFloat(item.avgOffersTotalQty)).reduce((a, b) => (a + b)) / avgValues.length),
      });

      this.offersTotalAmountChart.xAxis.data = dates;
      this.offersTotalAmountChart.series = offersTotalAmountChartDatasets;

      this.offersNumberChart.xAxis.data = dates;
      this.offersNumberChart.series = offersNumberChartDatasets;

      this.offersTotalQtyChart.xAxis.data = dates;
      this.offersTotalQtyChart.series = offersTotalQtyChartDatasets;

      this.ordersTotalAmountChart.xAxis.data = dates;
      this.ordersTotalAmountChart.series = ordersTotalAmountChartDatasets;

      this.ordersNumberChart.xAxis.data = dates;
      this.ordersNumberChart.series = ordersNumberChartDatasets;

      this.ordersTotalQtyChart.xAxis.data = dates;
      this.ordersTotalQtyChart.series = ordersTotalQtyChartDatasets;

      this.conversionRateChart.xAxis.data = dates;
      this.conversionRateChart.series = conversionRateChartDatasets;

      this.avgValuesTable.items = avgValues;
      this.avgValuesTable.totalRows = avgValues.length;
    },
    async getEmployeeNames() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-support-employee-names/`, {});
        this.allEmployees = response.data.results.map(item => item.full_name);
        this.selectedEmployees = response.data.results
          .filter(employee => employee.full_name !== "Hendirk Homann")
          .filter(employee => employee.full_name !== "Lukas Homann")
          .filter(employee => employee.full_name !== "Angela Melzer")
          .map(employee => employee.full_name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getOrdersOffersOverview() {
      this.showOrdersOffersOverview = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-employee-and-date/`, this.queryParams);
        this.updateOrdersOffersCharts(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrdersOffersOverview = false;
      }
    },
    async getOrdersOffersByEmployee() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-employee/`, this.queryParams);
        const results = response.data.results;
        this.ordersOffersByEmployeeTable.items = results;
        this.ordersOffersByEmployeeTable.totalRows = results.length;
        this.employeesTotalTurnover = results[0].total_turnover;
        this.totalOffersAmount = results
          .filter(item => item.employee !== "Webshop Import")
          .map(item => item.offers_value)
          .reduce((sum, value) => sum + value, 0);
        this.totalOpenOrders = results
          .filter(item => item.employee !== "Webshop Import")
          .map(item => item.open_orders_value)
          .reduce((sum, value) => sum + value, 0);
        this.totalPersonalCosts = results
          .filter(item => item.employee !== "Webshop Import")
          .map(item => item.personal_costs)
          .reduce((sum, value) => sum + value, 0);
        this.totalMargin = results
          .filter(item => item.employee !== "Webshop Import")
          .map(item => item.margin)
          .reduce((sum, value) => sum + value, 0);
        this.totalRealMargin = results
          .filter(item => item.employee !== "Webshop Import")
          .map(item => item.real_margin)
          .reduce((sum, value) => sum + value, 0);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getOrdersOffersByEmployeePrevYear() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-employee/`, this.queryParamsPrevYear);
        const results = response.data.results;
        this.ordersOffersByEmployeePrevYearTable.items = results;
        this.ordersOffersByEmployeePrevYearTable.totalRows = results.length;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getEmployeeOffers() {
      this.showEmployeeOffers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-offers/`, this.queryParamsEmployeeOffers);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.employeeOffersTable.items = results;
          this.employeeOffersTable.totalRows = results[0].count * 2;
        }
        else {
          this.employeeOffersTable.items = [];
          this.employeeOffersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showEmployeeOffers = false;
      }
    },
    async getEmployeeOrders() {
      this.showEmployeeOrders = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-orders/`, this.queryParamsEmployeeOrders);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.employeeOrdersTable.items = results;
          this.employeeOrdersTable.totalRows = results[0].count * 2;
        }
        else {
          this.employeeOrdersTable.items = [];
          this.employeeOrdersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showEmployeeOrders = false;
      }
    },
    async getEmployeeTopCustomers() {
      this.showTopCustomers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-orders-by-customers/`, this.queryParamsEmployeeTopCustomers);
        const results = response.data.results;
        this.topCustomersChart.xAxis.data = results.map(item => item.customer_name);
        this.topCustomersChart.series[0] = {
          name: "Turnover",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTopCustomers = false;
      }
    },
    async getWebshopTurnover() {
      this.showWebshopTurnover = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/webshop-turnover-by-date/`, this.queryParams);
        const results = response.data.results;
        this.webshopTurnoverChart.xAxis.data = results.map(item => item.month_year);
        this.webshopTurnoverChart.series[0] = {
          name: "Turnover",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
        this.webshopMarginChart.xAxis.data = results.map(item => item.month_year);
        this.webshopMarginChart.series[0] = {
          name: "Margin",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.margin),
        };
        this.webshopTotalTurnover = results[0].total_turnover;
        this.webshopTotalMargin = results.map(item => item.margin).reduce((sum, value) => sum + value, 0);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showWebshopTurnover = false;
      }
    },
    async getWorkingHoursByTeam() {
      this.showWorkingHoursByTeam = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/working-hours-by-team/`, this.queryParams);
        const data = response.data;
        let teams = data.map(item => item.team);
        let month_year = data.map(item => item.month_year);
        teams = [...new Set(teams)];
        month_year = [...new Set(month_year)];

        const workingHoursByTeamDatasets = [];
        let findValues;
        for (var i = 0; i < teams.length; i++) {
          const workingHoursByTeam = [];
          for (var j = 0; j < month_year.length; j++) {
            findValues = false;
            for (var k = 0; k < data.length; k++) {
              if (teams[i] === data[k].team && month_year[j] === data[k].month_year) {
                workingHoursByTeam.push(data[k].working_hours.toFixed(2));
                findValues = true;
                data.splice(k, 1);
                break;
              }
            }
            if (!findValues) {
              workingHoursByTeam.push(0.0);
            }
          }
          workingHoursByTeamDatasets.push({
            name: teams[i],
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: workingHoursByTeam,
          });
        }

        this.workingHoursByTeamChart.xAxis.data = month_year;
        this.workingHoursByTeamChart.series = workingHoursByTeamDatasets;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showWorkingHoursByTeam = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        const fromDateSelectedYearDate = new Date(dateRange[0]);
        const toDateSelectedYearDate = new Date(dateRange[1]);
        const fromDatePrevYearDate = new Date(fromDateSelectedYearDate.getFullYear() - 1, fromDateSelectedYearDate.getMonth(), fromDateSelectedYearDate.getDate());
        const toDatePrevYearDate = new Date(toDateSelectedYearDate.getFullYear() - 1, toDateSelectedYearDate.getMonth(), toDateSelectedYearDate.getDate());

        // Format dates
        const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        this.queryParamsPrevYear.from_date = formatDate(fromDatePrevYearDate);
        this.queryParamsPrevYear.to_date = formatDate(toDatePrevYearDate);

        await this.getOrdersOffersByEmployee();
        await this.getOrdersOffersByEmployeePrevYear();
        await this.getOrdersOffersOverview();
        await this.getWebshopTurnover();
        await this.getWorkingHoursByTeam();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.conversionRateChart.refresh();
          this.$refs.webshopTurnoverChart.refresh();
          this.$refs.webshopMarginChart.refresh();
          this.$refs.offersTotalAmountChart.refresh();
          this.$refs.ordersTotalAmountChart.refresh();
          this.$refs.offersNumberChart.refresh();
          this.$refs.ordersNumberChart.refresh();
          this.$refs.offersTotalQtyChart.refresh();
          this.$refs.ordersTotalQtyChart.refresh();
          this.$refs.workingHoursByTeamChart.refresh();
        });
      }
    },
    async changeSelectedEmployees() {
      if (this.dateRange) {
        const dateRange = this.dateRange.split(' to ');
        const fromDateSelectedYearDate = new Date(dateRange[0]);
        const toDateSelectedYearDate = new Date(dateRange[1]);
        const fromDatePrevYearDate = new Date(fromDateSelectedYearDate.getFullYear() - 1, fromDateSelectedYearDate.getMonth(), fromDateSelectedYearDate.getDate());
        const toDatePrevYearDate = new Date(toDateSelectedYearDate.getFullYear() - 1, toDateSelectedYearDate.getMonth(), toDateSelectedYearDate.getDate());

        // Format dates
        const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        this.queryParamsPrevYear.from_date = formatDate(fromDatePrevYearDate);
        this.queryParamsPrevYear.to_date = formatDate(toDatePrevYearDate);
      }
      this.queryParams.selected_employees = this.selectedEmployees;
      await this.getOrdersOffersByEmployee();
      await this.getOrdersOffersByEmployeePrevYear();
      await this.getOrdersOffersOverview();

      this.$nextTick(() => {
        this.$refs.conversionRateChart.refresh();
        this.$refs.webshopTurnoverChart.refresh();
        this.$refs.webshopMarginChart.refresh();
        this.$refs.offersTotalAmountChart.refresh();
        this.$refs.ordersTotalAmountChart.refresh();
        this.$refs.offersNumberChart.refresh();
        this.$refs.ordersNumberChart.refresh();
        this.$refs.offersTotalQtyChart.refresh();
        this.$refs.ordersTotalQtyChart.refresh();
        this.$refs.workingHoursByTeamChart.refresh();
      });
    },
    async ordersOffersByEmployeeTableRowClicked(row) {
      this.employeeName = row.employee;
      this.queryParamsEmployeeOffers = {
        from_date: this.queryParams.from_date,
        to_date: this.queryParams.to_date,
        employee: this.employeeName,
      };
      this.queryParamsEmployeeOrders = {
        from_date: this.queryParams.from_date,
        to_date: this.queryParams.to_date,
        employee: this.employeeName,
      };
      this.queryParamsEmployeeTopCustomers = {
        from_date: this.queryParams.from_date,
        to_date: this.queryParams.to_date,
        employee: this.employeeName,
      };

      await this.getEmployeeOffers();
      await this.getEmployeeOrders();
      await this.getEmployeeTopCustomers();

      this.$refs['employee-details-modal'].show();
    },
    onEmployeeDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.topCustomersChart.resize();
      });
    },
    resetEmployeeDetailsModal() {
      this.employeeName = '';
    },
    async employeeOffersTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsEmployeeOffers.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsEmployeeOffers.ordering = value.sortBy;
      }
      await this.getEmployeeOffers();
    },
    async handleEmployeeOffersTablePageChange(value) {
      this.queryParamsEmployeeOffers.page = value;
      await this.getEmployeeOffers();
    },
    async employeeOrdersTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsEmployeeOrders.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsEmployeeOrders.ordering = value.sortBy;
      }
      await this.getEmployeeOrders();
    },
    async handleEmployeeOrdersTablePageChange(value) {
      this.queryParamsEmployeeOrders.page = value;
      await this.getEmployeeOrders();
    },
    exportOrdersOffersByEmployeeTable() {
      const exportedData = this.ordersOffersByEmployeeTable.items;
      // Convert the data to a worksheet
      let worksheet = XLSX.utils.json_to_sheet(exportedData);
      // Create a new workbook, and add the worksheet to it
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      // Export the workbook to an xlsx file
      XLSX.writeFile(workbook, 'PerformanceOverview.xlsx');
    },
    showPrevYearModal() {
      this.$refs['absolute-values-prev-year-modal'].show();
    },
    showAvgValuesModal() {
      this.$refs['avg-values-modal'].show();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
